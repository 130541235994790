
@media only screen and (min-width:0px) and (max-width:767px) {
    /*** css changes on 11/07/2024 **/
    .Bundle_Added {
        order: 1;
    }
    .custom_bundle_blk  .col-md-8 {
        order: 2;
    }
    body .endless_tab_sec>.container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    body .custom_bunde_left {
        padding: 45px 20px;
    }
    .cus_pro_quan_blk>*:nth-child(2) {
        text-align: right;
    }
    .cus_pro_quan_blk>* {
        width: 50%;
    }
    body .mobile_show {
        display: block;
    }
    .mobile_hide {
        display: none;
    }
    /*** css changes on 11/07/2024 Ends **/
    .refer_product_select_option_wrapper {
        margin: 13px 0 0 0;
    }
    .product_quick_view {
        display: block;
    }
    .refer_product_short_desc .refer_product_select_option_wrapper,
    .add_to_wishlist_wrapper,
    .refer_product_quickview_wrapper {
        display: none;
    }
    .bundle_product .refer-img-wrapper {
        height: auto;
    }
    .mob_site_menu {
        background: url(../images/icon/menu_bg.jpg) no-repeat center center;
    }
    .mob_main_cart {
        background: url(../images/icon/mob_cart.jpg) no-repeat center center;
    }
    .mob_main_user {
        background: url(../images/icon/user.jpg) no-repeat center center;
    }
    .hide_small_screen {
        display: none;
    }
    .mob_menu_wrapper .site_menu_wrapper_inner {
        clear: both;
        width: 100%;
    }
    .mob_site_menu,
    .mob_main_user,
    .mob_main_cart {
        height: 45px;
        width: 32.3%;
        display: inline-block;
        vertical-align: top;
        color: #FFF;
        text-align: center;
    }
    .mob_main_user {
        border-right: 1px solid #868686;
        border-left: 1px solid #868686;
        border-top: 0px solid #868686;
        border-bottom: 0px solid #868686;
    }
    .mob_site_content {
        left: -800px;
        position: absolute;
        top: 0px;
        width: 260px;
        height: 500%;
        z-index: 9999;
        overflow-y: auto;
        background-image: url(../images/icon/download.png);
        background-repeat: repeat-y;
        background-position: right 0px;
        background-color: rgb(255, 255, 255);
        transition: all 0.2s ease-in-out 0s;
    }
    .mob_main_user_wrapper {
        top: 44px;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, .15);
        width: 100%;
        left: 0;
        text-align: left;
        background: #FFF;
        z-index: 1;
        padding: 10px 15px;
    }
    .mob_main_user_wrapper ul li {
        margin: 0 0 8px 0;
        color: #323232;
        list-style: none;
    }
    .mob_main_user_wrapper ul li a {
        color: #000;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 400;
    }
    body.current .mob_site_content {
        left: 0;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
    body.current {
        left: 0;
        width: 100%;
        overflow: hidden;
        position: fixed;
        height: 100%;
    }
    .mob_site_content li a {
        font-size: 13px;
        font-weight: 700;
        color: #000;
        padding: 12px 20px;
        margin: 0;
        display: block;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
    }
    .headere_mobile .mob_site_content>* {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    body .main_menu_blk ul li a {
        padding: 12px 20px;
        margin: 0;
        line-height: 24px;
        font-size: 16px;
        text-align: left;
        text-transform: uppercase;
    }
    .mob_text_order {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
        padding: 9px 10px;
        color: #141414;
        margin: 0;
        line-height: 30px;
        letter-spacing: 0.02em;
        opacity: 0.7;
        font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
    }
    .mob_header_logo {
        border-bottom: 1px solid #e7e7e7;
        float: left;
        margin: 28px 0 20px;
        padding-bottom: 38px;
        text-align: center;
        width: 100%;
    }
    .mob_header_logo a img {
        width: 147px;
    }
    .site-header {
        margin: 0;
    }
    .user_login {
        width: 100%;
    }
    .new_distributor_sign_up {
        padding: 0;
        margin: 12px 0 -7px 0;
        width: 100%;
    }
    .lost_password {
        margin: 0px;
    }
    .footer-top {
        text-align: center;
    }
    .footer_contact {
        margin: 0 0 6px 0;
    }
    .site-footer h3 {
        margin: auto auto 10px auto;
    }
    .site-footer .widget {
        margin: 15px 0;
    }
    .footer-top ul.social,
    .footer-top .text {
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin: 0 6px;
    }
    .user_name_email_field input,
    .cus_button,
    .input_type_file input[type=file],
    .name_field,
    .user_registration {
        width: 100%;
    }
    .product-list-container {
        width: 100%;
    }
    .scrollup {
        padding: 0px 10px;
        right: 15px;
    }
    .cart_add_product_img {
        width: 80px;
        height: 80px;
    }
    .variant-changer {
        width: 100px;
    }
    .my_account_orders thead {
        display: none;
    }
    .shop_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
    }
    .shop_table_responsive tr td {
        display: block;
        text-align: right!important;
        border-width: 0px 0px 1px 0px;
    }
    .shop_table_responsive tr td:last-child {
        border-width: 1px 0px 0px 0px !important;
        padding: 15px;
    }
    .shop_table_responsive tr {
        display: block;
        margin-bottom: 20px;
    }
    .table_search {
        text-align: left;
    }
    .table_search select,
    .table_search input {
        display: inline-block;
        width: 100% !important;
        margin: 6px 0;
    }
    .inner_content {
        margin-top: 20px;
    }
    .my_account_orders tr td .order-rsp-change {
        margin: 0 0 0 10px;
    }
    .page-title {
        font-size: 15px;
        margin: 10px;
    }
    .billing_address_container {
        width: 100%;
        margin: 30px 0 0 0;
    }
    .left_menu {
        margin: 0 0 20px 0;
        padding: 15px;
    }
    .get_in_touch .form-group input[type=text] {
        width: 100%;
    }
    .my_account .col-md-6 {
        padding: 0px;
        margin: 10px 0;
    }
    .action_form {
        padding: 15px;
    }
    .my_account .cus_button {
        width: auto;
    }
    .terms_and_condition h3 {
        font-size: 16px;
        margin: 0 0 3px 0;
    }
    .athletes_list img {
        width: 100%;
        height: auto;
    }
    .view-controls-wrapper {
        opacity: 0 !important;
        display: none !important;
    }
    .alert-wrapper a {
        display: block;
        float: inherit;
        position: inherit;
        margin: 10px 0 0 0;
        width: 100%;
    }
    .short-description p,
    .product_title {
        font-size: 18px;
    }
    .product_tvc_wrapper {
        width: 100%;
        margin: 15px 0;
    }
    .swatch_block div {
        margin: 5px 0;
    }
    .cart_totals {
        border: 1px solid #e1e1e1;
    }
    .mob_display_none {
        display: none !important;
    }
    .shipping-methods span,
    .shipping-methods label {
        font-size: 13px;
    }
    .sub-order-total-usd span {
        font-size: 16px !important;
    }
    .col2-set {
        width: 100%;
    }
    .woocommerce-checkout-payment {
        padding: 14px;
        margin: 0 0 30px 0;
    }
    .payment_box {
        padding: 10px;
    }
    .fieldset {
        margin: 0px;
    }
    .card_exp_date select,
    .fieldset .name_field {
        width: 100% !important;
    }
    /** table responsive **/
    .cart_table_responsive tr td::before,
    .cart_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
        font-size: 14px;
    }
    .cart_table_responsive thead {
        display: none;
    }
    .cart_table_responsive tr td {
        display: block;
        padding: 15px !important;
        text-align: right;
    }
    .cart_table_responsive tr td a {
        display: inline-block;
        text-align: center;
    }
    .cart_table_responsive .cart_product_image {
        display: none;
    }
    .cart_table_responsive .cart_product_details {
        margin: 0px 0px 0px 0px;
        float: none;
        width: 270px;
        /* text-align: right; */
    }
    .cart_table_responsive .cart_product_details a {
        display: inline-block;
        width: 64%;
    }
    .cart_table tr td.product-thumbnail {
        width: 100%;
    }
    .cart_table_responsive .actions .link-to-shop,
    .cart_table_responsive .actions .update_cart,
    .cart_table_responsive .actions .cus_button,
    .cart_table_responsive .actions .input-text {
        margin: 5px 0px;
        width: 100%;
    }
    .actions:before {
        display: none;
    }
    .countinue_shop,
    .coupon {
        width: 100%;
        float: none;
    }
    .cart_table_responsive .actions {
        padding: 10px 10px 0 10px;
    }
    .cart_table_responsive tr td {
        border-width: 0px 0px 1px 0px;
    }
    .cart_table_responsive .cart_product_remove:before {
        display: none;
    }
    .cart_table_responsive .cart_product_remove {
        width: 70px;
        text-align: center !important;
        border-right: 1px solid #e1e1e1 !important;
    }
    .cart_product_number input {
        text-align: center;
    }
    .faq_container {
        padding: 10px;
    }
    .faq_container h4 {
        font-size: 15px;
        line-height: 1.2;
        color: #4e4e4e;
        margin: 10px 0;
    }
    .cus_breadcrumb {
        /* padding: 0px 10px; */
    }
    .refer-img-wrapper {
        text-align: center;
    }
    .onsale {
        right: 30px;
    }
    .refer_product_details {
        padding: 0 15px;
    }
    .short-description h3 {
        font-size: 15px;
    }
    .short-description div:first-child {
        float: none !important;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .checkout_coupon_apply .input-coupon {
        width: 100%;
        margin: 0 0 15px 0;
    }
    .refer-img-thumb {
        height: auto;
        width: auto;
    }
    .cart_page_product_img {
        display: none;
    }
    .cart_product_number .decrement,
    .cart_product_number .qty,
    .cart_product_number .increment {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
    .cart_page_data_list {
        border: 1px solid #e1e1e1;
    }
    .no_display_mobile {
        display: none !important;
    }
    .recurring_total {
        padding: 10px !important;
        font-size: 16px !important;
        font-weight: 900 !important;
    }
    .total_per_month {
        margin: 0px 0 0 0;
        font-size: 15px;
    }
    td.cart_page_product_action::before,
    .shop_table_responsive tr td.cart_product_remove::before {
        display: none;
    }
    .cart_product_remove {
        width: 58px;
        border-width: 0 1px 1px 0 !important;
        text-align: center !important;
    }
    .Price-currencySymbol,
    .cart_product_details {
        margin: 0px;
    }
    .cart_page_product_coupon {
        float: none;
        margin-bottom: 10px;
    }
    .cart_page_product_coupon .input-text,
    .countinue_shop .update_cart {
        float: left !important;
        margin: 0 !important;
        width: 47% !important;
    }
    .cart_page_product_coupon .cus_button {
        width: 40%;
    }
    .refer_login {
        width: 100%;
    }
    .error404Img img {
        width: 100%;
    }

    /* .modal_meal_product_view .modal_product_view_img .carousel:last-child {
        position: relative!important;
        right: inherit!important;
        width: auto !important;
        top: inherit!important;
    } */
}

@media only screen and (min-width:320px) and (max-width:1024px) {}

@media only screen and (min-width:0px) and (max-width:767px) {}

@media only screen and (min-width:0px) and (max-width:756px) {}

@media only screen and (min-width:768px) and (max-width:1900px) {}


/** office samsung tab **/

@media only screen and (min-width:768px) and (max-width:1024px) {
    body .public_bundle_product.public_ban_section  .owl-carousel .owl-item img {
        height: 300px;
    }
    .site_menu.fixed-header .header-logo-fix {
        left: 0;
    }
    .refer_product_select_option_wrapper {
        margin: 13px 0 0 0;
    }
    .site_menu.fixed-header ul {
        margin: 0px 0px 0px 65px;
    }
    .site_menu ul li a.menu_item {
        margin: 18px 14px 13px;
    }
    .site_menu.fixed-header ul li a.menu_item {
        margin: 18px 10px 13px;
    }
    .site_menu.fixed-header ul li.pull-right {
        float: none;
    }
    .entry-content .faq_container {
        padding: 10px;
    }
    .entry-content .faq_container {
        padding: 10px;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .checkout_coupon_apply .input-coupon {
        width: 100%;
        margin: 0 0 15px 0;
    }
    .refer_cart_details {
        width: 215px;
        display: inline-block;
        vertical-align: middle;
        margin: 0px 20px 0px 20px;
    }
    .refer_login {
        width: 100%;
    }
    .error404Img img {
        width: 100%;
    }
    /* .modal_meal_product_view .modal_product_view_img .carousel:last-child {
        position: relative!important;
        right: inherit!important;
        width: auto !important;
        top: inherit!important;
    } */
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {}


/** this css for ipad office **/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    .error404Img img {
        width: 100%;
    }
    .bundle_product .refer-img-wrapper {
        height: auto;
    }
    .refer_product_select_option_wrapper {
        margin: 13px 0 0 0;
    }
    .product-list-container {
        margin: auto auto 50px auto;
        width: 100%;
    }
    .my_account_orders tr td,
    .my_account_orders tr th,
    .receive_order_details tr td {
        padding: 5px;
        font-size: 13px;
    }
    .cart_product_details {
        margin: 0 5px;
        width: 176px;
    }
    .my_account_orders tr td a,
    .receive_order_details tr td a {
        font-size: 13px;
    }
    .cart_product_number input {
        padding: 5px;
        width: 50px;
        border-radius: 0px;
    }
    .increment,
    .decrement {
        background: #dddddd;
    }
    .cart_table .actions .input-text {
        border-radius: 0px;
    }
    .coupon.checkout_coupon {
        width: 100%;
        float: none;
        margin: 0 0 15px 0;
    }
    .woocommerce-checkout-payment {
        padding: 10px;
        margin: 0 0 20px 0;
    }
    .payment_box {
        padding: 8px;
        margin: 10px 0;
        border-radius: 0;
        background-color: #f6f6f6;
        color: inherit;
        font-size: 13px;
    }
    .fieldset {
        padding: 5px;
        margin: 0px;
    }
    .review-order-table-wrapper {
        margin: 0 0 20px 0;
    }
    .cart_totals {
        padding: 10px;
    }
    .left_menu {
        padding: 5px;
    }
    .page-title {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .table_search select,
    .table_search input {
        width: 23%;
    }
    .my_account_orders thead {
        display: none;
    }
    .shop_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
    }
    .shop_table_responsive tr td.order-representative::before,
    .shop_table_responsive tr td.order-total::before {}
    .order-actions::before,
    td.cart_page_product_action::before,
    .shop_table_responsive tr td.cart_product_remove::before {
        display: none;
    }
    .shop_table_responsive tr td {
        display: block;
        text-align: right;
        border-width: 0px 0px 1px 0px;
    }
    .shop_table_responsive tr td:last-child {
        border-width: 1px 0px 0px 0px !important;
        padding: 15px;
    }
    .shop_table_responsive tr {
        display: block;
        margin-bottom: 20px;
    }
    .my_account_orders tr td .order-rsp-change {
        float: none;
    }
    .table_search select,
    .table_search input {
        width: 40%;
        float: left;
    }
    .order_search.table_search select,
    .order_search.table_search input {
        display: inline-block;
        width: 110px;
        margin: 0 0 0 6px;
        float: none;
    }
    .to_hide span.member_edit_cancel {
        width: auto;
    }
    .table_search_form_group label {
        display: block;
        text-align: left;
    }
    .date_by_day {
        width: 64%;
        float: left;
    }
    .shop_table_responsive .order-number a {
        float: none;
    }
    .team_member_edit select {
        display: inline-block;
        margin: 0;
        width: auto;
    }
    .entry-content .faq_container {
        padding: 10px;
    }
    .entry-content .faq_container {
        padding: 10px;
    }
    .coupon-wrapper-container {
        width: 100%;
        margin-bottom: 30px;
    }
    .checkout_coupon_apply .input-coupon {
        width: 100%;
        margin: 0 0 15px 0;
    }
    /** cart table responsive **/
    .cart_table_responsive tr td::before,
    .cart_table_responsive tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
        font-size: 14px;
    }
    .cart_table_responsive thead {
        display: none;
    }
    .cart_table_responsive tr td {
        display: block;
        padding: 15px !important;
        text-align: right;
    }
    .cart_table_responsive tr td a {
        display: inline-block;
        text-align: center;
    }
    .cart_table_responsive .cart_product_image {
        display: none;
    }
    .cart_table_responsive .cart_product_details {
        margin: 0px 0px 0px 0px;
        float: none;
        width: 270px;
        /* text-align: right; */
    }
    .cart_table_responsive .cart_product_details a {
        display: inline-block;
        width: 64%;
    }
    .cart_table tr td.product-thumbnail {
        width: 100%;
    }
    .cart_table_responsive .actions .link-to-shop,
    .cart_table_responsive .actions .update_cart,
    .cart_table_responsive .actions .cus_button,
    .cart_table_responsive .actions .input-text {
        margin: 5px 0px;
        width: 100%;
    }
    .actions:before {
        display: none;
    }
    .countinue_shop,
    .coupon {
        width: 100%;
        float: none;
    }
    .cart_table_responsive .actions {
        padding: 10px 10px 0 10px;
    }
    .cart_table_responsive tr td {
        border-width: 0px 0px 1px 0px;
    }
    .cart_table_responsive .cart_product_remove:before {
        display: none;
    }
    .cart_table_responsive .cart_product_remove {
        width: 70px;
        text-align: center !important;
        border-right: 1px solid #e1e1e1 !important;
    }
    .cart_product_number .decrement,
    .cart_product_number .qty,
    .cart_product_number .increment {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
    .cart_page_product_img {
        display: none;
    }
    .cart_page_data_list {
        border: 1px solid #e1e1e1;
    }
    .no_display_mobile {
        display: none !important;
    }
    .cart_page_product_coupon .cus_button,
    .cart_table_responsive .actions .link-to-shop,
    .cart_page_product_coupon .input-text,
    .countinue_shop .update_cart {
        float: left !important;
        margin: 8px !important;
        width: 47% !important;
    }
    /** cart table responsive **/
    .shop_table tr td {
        padding: 10px !important;
    }
    .refer_login {
        width: 100%;
    }
    /* .modal_meal_product_view .modal_product_view_img .carousel:last-child {
        position: relative!important;
        right: inherit!important;
        width: auto !important;
        top: inherit!important;
    } */
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {}


/** //EnD this css for ipad office **/

@media only screen and (min-width: 1025px) and (max-width:1366px) {
    .site_menu.fixed-header ul {
        margin: 0 0 0 100px;
        width: 88%;
    }
    .site_menu.fixed-header .header-logo-fix {
        left: 0;
    }
    /* .modal_meal_product_view .modal_product_view_img .carousel:last-child {
        position: relative!important;
        right: inherit!important;
        width: auto !important;
        top: inherit!important;
    } */
}
@media only screen and (max-width: 389px) {
    body div .public_bundle_product.public_ban_section  .owl-carousel .owl-item img {
        height: 140px;
    }
}

/**** css changes on 17/03/2023 **********/
@media only screen and (max-width: 767px){
    body .font_44 {
        font-size: 24px;
        line-height: 38px;
    }
    body .mr_27 {
        margin-bottom: 20px;
        margin-right: 0;
    }
    body .second_options_blk {
        min-height: inherit;
        margin-bottom: 50px;
    }
    body .quiz_options.sec_quiz_options:not(:last-child) {
        margin-bottom: 27px;
        margin-right: 0;
    }
    body .ml_27 {
        margin-left: 0;
        margin-top: 10px;
    }
    body .gender_quiz_button, body .first_quiz_button {
        width: 100%;
    }
    body .next_button.buy_now {
        min-width: 170px;
        height: 60px;
    }
    body .quiz_blk {
        padding: 25px 25px 85px 25px;
    }
    body .hide_image {
        padding-bottom: 250px;
    }
    body .pro_left_blk .pro_img_carousel .mkn-img-container img, body .pro_left_blk .pro_img_carousel .slider-wrapper.axis-horizontal {
        height: auto;
    }
    .best_selling_pro .row > *:not(:last-child) {
        padding-bottom: 10px;
     }
     body .public_bundle_product.public_ban_section  .owl-carousel .owl-item img {
        height: 170px;
        object-fit: cover;
    }
     body .public_bundle_product .owl-theme .owl-nav {
        margin-top: 20px;
        position: static;
    }
    body .best_selling_para.top_selling-para {
        width: 100%;
    }
    .order_container .product_search_input {
        width: 100%;
        margin-top: 20px;
    }
    .error404Img {
        padding-top: 30px;
    }
    .error404 {
        padding-bottom: 30px;
    }  
    body .pro_det_div_des .pull-left, body .pro_det_div_des .pull-right {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
    }
    .d-flex {
        flex-wrap: wrap;
    }
    .customer_menu li {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 15px;
    }
    .customer_menu li a {
        display: flex;
        justify-content: center;
    }
    .sub_btm_det {
        width: 100%;
        margin-top: 10px;
        justify-content: flex-end;
    }
    .order_pro_detail>* {
        width: 100% !important;
    }
    .order_pro_detail>*:not(:last-child) {
       margin-bottom: 10px;
    }
    .order_on_div, .order_status, body .w-50 {
        width: 100% !important;
    }
    body .order_accordatio {
        padding: 20px;
    }
    .order_status.ml_20 {
        justify-content: center;
        width: fit-content !important;
    }
    .rgt_acc {
        width: 100% !important;
        margin-top: 10px;
    }
    .rgt_acc a {
        margin-bottom: 17px;
    }
    .order_det_blk {
        margin-top: 20px;
        padding: 20px;
    }
    .billing_blk {
        margin-bottom: 25px;
    }
    .pay_top {
        margin-top: 25px;
    }
    .subscription_ul {
        width: 1140px;
    }
    body .sub_current_div {
        padding: 20px;
    }
    .sub_current_div>*>* {
        width: 100% !important;
    }
    .billing_blk.sub_billing_blk.mb_25 {
        margin-top: 25px;
    }
    .sub_current_div .col-md-4 {
        justify-content: flex-start !important;
        margin-top: 16px;
    }
    .sub_current_div>*>*.buy_now {
        width: fit-content !important;
    }
    body .add_new_card {
        padding: 20px;
    }
    .form_input_blk  form {
        padding: 0;
    }
    .add_new_btn {
        width: 100%;
    }
    .form-control>input {
        width: 50% !important;
    }
    body .refer_banner_slide {
        padding-left: 15px;
        padding-right: 15px;
    }
    body .banner_heading {
        font-size: 35px;
        width: 100%;
        line-height: 46px;
        margin-bottom: 20px;
        text-align: center;
    }
    .mob_site_menu {
        background-position: 30px;
    }
    .carousel-item .left_banner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mr_36 {
        margin-right: 15px !important;
    }
    body .buy_now, body .view_detail, body .shop_more.take_quiz_btn {
        padding: 13.5px 15px;
    }
    body .font_41 {
        font-size: 24px;
        line-height: 35px;
    }
    body .best_selling_para {
        width: 100%;
    }
    body .best_selling_section, body .explore_product_section, body .shop_goal_section, body .explore_product_section.testimonial_section,
    body section.your_fitness_section {
        padding-left: 0;
        padding-right: 0;
    }
    body .exp_col_col {
        width: 100%;
        margin-bottom: 20px;
    }
    .goal_row.row>* {
        padding-bottom: 13px;
    }
    body .testsimonial_col {
        padding: 20px;
    }
    body .testsimonial_col {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .testi_left {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    .testi_right {
        padding: 20px 0 0 0;
    }
    body .fitness_left, body .fitness_rgt, body .fitness_left img, body .our_story_left, .our_story_left img {
        width: 100%;
    }
    body .fitness_text {
        font-size: 35px;
        line-height: 46px;
        margin-top: 25px;
    }
    body .our_story_section{
        padding-left: 15px;
        padding-right: 15px;
    }
    .play_icon{
        width: 50px !important;
    }
    body .our_story_right {
        width: 100%;
        padding: 20px;
    }
    body .site-footer.refer_footer {
        padding: 30px 0px;
    }
    .location_blk span {
        width: calc(100% - 13px);
    }
    body .site-footer.refer_footer .row>*:not(:last-child) {
        padding-bottom: 30px;
    }
    body .pro_left_blk {
        padding: 30px 15px;
    }
    nav[aria-label="breadcrumb"] + img {
        margin-top: 20px;
    }
    body .pro_rgt_blk {
        padding: 30px 15px;
    }
    body .quan_cart_blk .cart_add_product_btn {
        width: calc(100% - 140px);
    }
    .del_pri {
        width: 30%;
    }
    .del_det {
        width: 70%;
        padding-left: 10px;
    }
    .dis_span.ml_16 {
        margin: 10px 0 !important;
    }
    body .product_tabs li.nav-item {
        margin: 0 0 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    body .product_tabs li.nav-item>* {
        margin:  0;
    }
    body .ben_letf,  body .ben_letf img, body .howuse_heading, body .faq_section .container,
    body .like_pro_para, .wri_review_blk >* {
        width: 100% !important;
    }
    body .ben_rgt {
        width: 100%;
        padding: 20px 0 0 0;
    }
    .benefit_ul li span {
        padding-left: 15px;
        width: calc(100% - 24px);
    }
    .benefits_blk {
        padding-bottom: 30px;
    }
    .how_use_row>* {
        padding-bottom: 30px;
    }
    .faq_section .accordion-header .accordion-button {
        position: relative;
    }
    .faq_section .accordion-header .accordion-button img {
        position: absolute;
        right: 15px;
    }
    body .wri_review_blk {
        padding: 20px;
    }
    .rev_rgt  {
        padding-top: 20px;
    }
    .cart_pro_list .cart_pro_list_left {
        width: 100%;
        padding-bottom: 15px;
    }
    .cart_pro_list .cart_total {
        width: 100%;
    }
    .cart_rgt_blk {
        margin-top: 30px;
    }
    body .coupon_input {
        width: calc(100% - 82px);
    }
    .checkout_left_blk {
        padding: 30px 15px;
    }
    .checkout_row  .shop_more.take_quiz_btn {
        margin-top: 20px;
    }
    .checkout_ul li .pl_10 {
        padding: 10px 0 0 0;
    }
    .checkout_ul li {
        flex-direction: column;
    }
    body .checkout_rgt_blk {
        padding: 30px 15px;
    }
    .shipping_left>*, body .order_confirmed_page .container {
        width: 100%;
    }
    .shipping_left>*.pl_6 {
        padding-top: 6px;
        padding-left: 0;
    }
    .upi_row.row>* {
        padding-bottom: 20px;
    }
    .checkbox_label .checbox + span {
        width: calc(100% - 14px);
    }
    body .thank_you_header, body .thank_you_body, body .thank_btn {
        padding: 20px;
    }
    ul.pro_cart_det.payemnt_total_pri {
        margin-top: 20px;
    }
    .order_no_date span {
        margin-bottom: 10px;
        text-align: left !important;
    }
    .thank_btn>*>.col-md-8 {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .main_menu_blk {
        display: none;
    }
    .banner_section .banner_right_img {
        width: 100% !important;
        height: inherit;
    }
    body .banner_heading {
        font-size: 41px;
        line-height: 53px;
        margin-bottom: 35px;
        width: 100% !important;
    }
    body .buy_now, body .view_detail {
        padding: 13px 20px;
    }
    .best_selling_pro .col-md-3, .goal_row  .col-md-4 {
        width: 50%;
        flex: inherit;
        max-width: inherit;
    }
    .goal_row  .col-md-4 {
        padding-bottom: 18px;
    }
    body .explore_img {
        width: 100%;
        height: 100%;
    }
    body .exp_col_col {
        width: 100%;
        margin: 0 auto;
    }
    .exp_col_col  h3 {
        text-align: center;
    }
    .header_blk .hide_small_screen .container-fluid, body .refer_banner_slide,body .explore_product_section, body .shop_goal_section,
    body .explore_product_section.testimonial_section, body section.your_fitness_section, body .site-footer.refer_footer {
        padding-left: 20px;
        padding-right: 20px;
    }
    .Testimonial_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    body .fitness_left {
        width: 30%;
    }
    body .fitness_left img, body .our_story_left img, body .our_story_left, body .our_story_right {
        width: 100%;
    }
    body .fitness_rgt {
        width: 70%;
    }
    body .fitness_text {
        font-size: 41px;
        line-height: 46px;
    }
    .our_story_section>.d-flex {
        flex-wrap: wrap;
    }
    .play_icon {
        width: 50px !important;
    }
    .site-footer.refer_footer .row>.col-md-4 {
        width: 66%;
        flex: inherit;
        max-width: inherit;
        padding-bottom: 30px;
    }
    .site-footer.refer_footer .row>.col-md-2 {
        width: 33.33%;
        flex: inherit;
        max-width: inherit;
    }
    .site-footer.refer_footer .row>.col-md-4 + * {
        display: none;
    }
    .pro_left_blk.col-md-6, .pro_rgt_blk.col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 20px;
    }
    body .product_tabs>li .nav-link {
        margin-right: 20px;
    }
    body .ben_letf {
        width: 30%;
    }
    body .ben_letf img, body .howuse_heading {
        width: 100%;
    }
    body .ben_rgt {
        width: 70%;
        padding-left: 15px;
    }
    .how_use_row>* {
        flex: inherit;
        max-width: inherit;
        width: 50%;
        padding-bottom: 30px;
    }
    body .faq_section .container {
        width: 720px;
    }
    body .rev_left {
        width: 40%;
    }
    body .checkout_left_blk, body .checkout_rgt_blk {
        max-width: inherit;
        flex: inherit;
        width: 100%;
        padding: 30px 20px;
    }
    .dashboard_row>.col-md-4 {
        max-width: inherit;
        flex: inherit;
        width: 100%;
    }
}
/**** css changes on 17/03/2023 **********/

/**** css changes on 19/03/2023 **********/
@media only screen and (max-width: 767px) {
    body .product_search_input {
        width: 100%;
        margin-top: 20px;
    }
    body .login_container {
        min-height: inherit;
    }
    body .pro_filter_sec {
        width: calc(100% - 30px);
    }
    .pro_filter_sec>*:first-child, .pro_filter_sec>*:nth-child(2) {
        margin-bottom: 10px;
    }
    .header_blk .mob_header_logo {
        border: none;
        margin: 0;
        float: none;
        padding: 20px;
    }
    .headere_mobile .mob_site_menu {
        width: 50px;
        background: url('../images/public_images/menu.svg') no-repeat center;
    }
    .headere_mobile {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0;
        align-items: center;
    }
    .headere_mobile .header_right_blk>ul>li {
        margin: 0 0 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #fff;
    }
    .headere_mobile .header_right_blk {
        width: 80%;
    }
    .headere_mobile .mob_site_content {
        width: 100%;
        background-color: var(--def_color);
        overflow: auto;
        height: 100%;
    }
    .menu_close {
        padding: 20px;
        pointer-events: none;
    }
    .headere_mobile .mob_site_content li a {
        justify-content: space-between;
    }
    .mob_site_content>li>a {
        width: 100%;
    }
    .headere_mobile .header_sub_menu .dropdown-menu {
        position: static !important;
        box-shadow: none;
        display: block;
    }
    .headere_mobile .shop_menurow>* {
        width: 100% !important;
    }
    .headere_mobile .shop_menurow>* .shop_menu_li {
        border: none;
    }
    .headere_mobile .shop_menurow {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
    }
    .headere_mobile .shop_menurow>* {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
    }
    .headere_mobile .main_menu_blk ul li:not(:last-child) {
        margin-right: 0;
    }
    .bundle_heading_blk .font_42 {
        font-size: 20px;
    }
    .bundle_heading_blk {
        width: 100% !important;
    }
    .bundle_heading_blk .create_bundle_btn {
        width: max-content !important;
    }
    .respons_flex_direction {
        flex-direction: column;
    }
    .respons_flex_direction .pro_head_blk {
        width: 100%;
        margin-bottom: 10px;
    }
    .respons_flex_direction .input_bundle_btn {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) and (min-width:768px) {
    .mob_menu_wrapper.d-md-none {
        display: block !important;
    }
    .main_menu_blk ul li a img {
        position: relative;
        top: -2px;
    }
    body .login_container {
        min-height: calc(100vh - 147px);
    }
    .mob_menu_wrapper.d-md-none .mob_header_logo {
        display: none;
    }
    .mob_menu_wrapper.d-md-none .mob_site_content {
        display: flex;
        justify-content: center;
    }
    .headere_mobile .header_right_blk {
        display: none;
    }
    .menu_close {
        display: none !important;
    }
    .header_blk {
        display: flex;
        flex-direction: column-reverse;
    }
    .mob_menu_wrapper.d-md-none {
        display: block !important;
        padding: 15px 0;
        border-top: 1px solid #fff;
    }
    .mob_menu_wrapper.d-md-none .main_menu_blk {
        display: block;
    }
    .shop_menurow .w-20.col {
        width: 33.33% !important;
    }
    body .header_sub_menu .dropdown-menu {
        top: 157px !important;
    }
    .login_li{
        display: none !important;
    }
    .cart_row.row>* {
        width: 100%;
        flex: inherit;
        max-width: inherit;
        padding-bottom: 34px;
    }
}
/**** css changes on 19/03/2023 - Ends **********/

/**** css changes on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
     /*** css changes on 03/07/2024 ***/
     body .pro_head_blk, body .input_bundle_btn, body .product_search_input.nxt_bundle_btn, body .build_bundle  {
        width: 100%;
    }
    body .product_search_input.nxt_bundle_btn {
        margin: 0 0 13.5px 0;
    }
    body .build_bundle {
        margin: 0;
    }
     body .header_search_blk {
        width: calc(100% - 15px);
        margin: 10px 0 0 15px;
    }
    body .sub_shop_now_btn {
        padding: 14px 24px;
    }
    .endless_section_blk h2 {
        margin-bottom: 9px !important;
    }
    .mobile_logo_blk  img {
        width: 150px;
    }
    body .support_tab_blk {
        width: 100%;
        padding: 0;
    }
    body .pro_det_div_des img {
        width: 100%;
        height: auto;
    }
    body .header_blk {
        position: static;
    }
    .choose_flavour .swatch_block div label {
        white-space: normal;
    }
    body .endless_support_tab_sec .public_bundle_product .owl-theme .owl-nav {
        width: 100%;
        -webkit-transform: inherit;
        transform: inherit;
        text-align: center;
        margin-top: 20px;
    }
    body .support_tab_blk ul {
        width: 100%;
    }
    body .support_tab_blk_ul {
        width: 100%;
        margin-bottom: 10px;
    }
    .support_tab_blk .create_bundle_btn {
        width: 100% !important;
    }
    body .refer_banner_slide.public_banner_slide .carousel-item {
        background-position: top right;
    }
    body .refer_banner_slide.public_banner_slide .carousel-item {
        padding: 170px 15px 40px 15px;
    }
    body .line_hei_75px {
        line-height: 35px !important;
    }
    body .smat_sav_blk {
        padding: 50px 15px;
    }
    body .buy_now.font_30 {
        font-size: 16px;
        line-height: 18px;
    }
    .about_us_blk .w-90 {
        width: 100%;
        padding-top: 20px;
    }
    body .mb_30 {
        margin-bottom: 20px;
    }
    br {
        display: none;
    }
    body .endless_section_blk {
        padding: 30px 0 30px 0;
    }
    body .mb_50 {
        margin-bottom: 30px;
    }
    body .mb_70 {
        margin-bottom: 40px;
    }
    body .banner_below {
        padding: 20px 0;
    }
    body .meet_our_section {
        background-position: center;
        height: 410px;
    }
    body .promotes_text {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    body .endless_para {
        font-size: 20px;
        line-height: 26px;
    }
    body .support_tab {
        font-size: 14px;
        line-height: 16px;
        padding: 10.4px 14px;
    }
    body .font_20, body .font_24 {
        font-size: 16px;
        line-height: 18px;
    }
    body .endless_text {
        font-size: 25px;
        line-height: 35px;
    }
    /*** css changes on 03/07/2024 Ends ***/
    body .pro_dec_blk {
        padding: 0px 15px 30px 15px;
    }
    body .pro_dec_blk .pro_det_div_des {
        padding-left: 0;
        padding-right: 0;
    }
    .public_bundle_product .owl-theme .owl-nav [class*='owl-'].owl-prev {
        margin-left: 0;
    }
    body .login_container .user_login {
        width: 100%;
    }
    body .login_container .add_new_card .checkbox_label {
        margin: 0 !important;
        width: 50%;
    }
    body .customer_dash_full_view .cus_dash_rgt {
        padding: 40px 15px;
    }
    .subscribtion_table tr td:not(:first-child) {
        white-space: nowrap;
    }
    .subscribtion_table tr td:first-child .left_pro_de {
        width: 200px !important;
    }
    body .pro_carousel.browsing_his_carousel {
        width: 100%;
    }
    .subscribtion_table tr td:first-child .left_pro_de {
        width: 200px !important;
    }
    .table_search_select {
        width: 100%;
        justify-content: flex-end;
        margin-top: 20px;
    }
    body .order_det_blk {
        padding: 20px;
    }
    body .w-22 {
        width: 100% !important;
    }
    .sub_btm_det li {
        margin: 0 0 10px 0 !important;
        width: 100%;
    }
    body .add_new_btn {
        width: 100%;
    }
    .subscription_end_date_edit_calender >*>* {
        width: 100%;
        display: flex !important;
        justify-content: center;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    /** css changes on 03/07/24 **/
    .tab_nowrap {
        white-space: nowrap;
    }
    .support_tab_blk .create_bundle_btn {
        margin-left: 0;
    }
    body .refer_banner_slide.public_banner_slide .carousel-item {
        padding: 40px 35px;
        background-size: cover;
        background-position: calc(100% - -87px);
    }
    body .endless_section_blk {
        padding: 40px 0 40px 0;
    }
    body .mb_50 {
        margin-bottom: 30px;
    }
    body .mb_70 {
        margin-bottom: 50px;
    }
    body .meet_our_section {
        height: 500px;
        background-position: center;
    }
    body .smat_sav_blk {
        padding: 35px;
    }
    .site-footer.refer_footer .row>* {
        padding-bottom: 30px;
    }
    body .endless_text {
        font-size: 35px;
        line-height: 45px;
    }
    body .endless_para {
        font-size: 20px;
        line-height: 30px;
    }
    body .support_tab_blk {
        width: 100%;
    }
    body .line_hei_75px {
        line-height: 45px !important;
    }
    br {
        display: none;
    }
    body .font_30.buy_now, body .font_24.sub_shop_now_btn {
        font-size: 16px;
        line-height: 18px;
    }
    .tab_hide {
        display: none;
    }
    /** css changes on 03/07/24 Ends **/
    .cus_dash_rgt .sub_current_div>*:first-child>* {
        width: 50%;
        flex:inherit;
        max-width: inherit;
    }
    body .pro_dec_blk {
        padding: 0px 20px 30px 20px;
    }
    body .pro_dec_blk .pro_det_div_des {
        padding-left: 0;
        padding-right: 0;
    }
    body .sub_btm_det {
        margin-top: 20px;
    }
    .sub_btm_det li {
        width: 50%;
        margin: 0 0 20px 0!important;
    }
    .subscription_end_date_edit_calender>*>* {
        width: 30% !important;
        margin: 0 !important;
        justify-content: center;
        display: flex !important;
    }
    .contact_container .account_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
}
/**** css changes on 24/03/2023 - Ends **********/

/**** css changes refer on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
    .register_recaptcha.contact_cppatcha .form-control {
        width: calc(100% - 190px);
    }
    button#refresh {
        opacity: 50%;
    }
    body .banner_right_img {
        height: auto;
        width: 200px !important;
        margin-top: 20px;
    }
    body .formulated_ul {
        position: static;
        width: 63%;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .rev_rgt.w-60 {
        width: 100% !important;
    }
    body .checkout_left_blk {
        padding: 30px 15px;
    }
    .checkout_ul li {
        width: 100% !important;
        flex-wrap: nowrap;
        flex-direction: row;
        border-radius: 50px !important;
        background-color: var(--sec_color);
    }
    .checkout_ul li.active .font_16 {
        color: #fff;
    }
    .back_navigation {
        padding-bottom: 20px;
    }
    body .checkout_ul li .pl_10 {
        padding: 0 0 0 10px !important;
    }
    .left_methid {
        width: calc(50%);
    }
    .credit_card_radio {
        width: 100%;
        margin-bottom: 10px !important;
    }
    body .checkout_ul  {
        background-color: transparent;
    }
    .contact_container .account_row>* {
        flex: inherit;
        max-width: inherit;
        width: 100%;
    }
    body .contact_cppatcha .form-control {
        width: calc(100% - 100px);
        margin: 0 20px 0 0;
    }
    body .captcha_container.contact_cppatcha h2 {
        margin-bottom: 16px;
    }
}
/**** css changes refer on 24/03/2023 - Ends **********/

/**** css changes refer on 24/03/2023 **********/
@media only screen and (max-width: 767px) {
    .headere_mobile .header_right_blk.before_login>ul>li {
        border: none;
        justify-content: flex-end;
    }
    .headere_mobile .header_right_blk.before_login ul {
        justify-content: flex-end;
        padding-right: 0;
    }
    body .carousel .thumbs-wrapper {
        margin: 0px;
    }
    body .pro_left_blk .carousel .thumbs li.thumb:not(:last-child) {
        margin-right: 2px !important;
    }
    .ship_rgt {
        width: 100%;
        margin-top: 10px;
    }
    body .shipping_left ,  body .contact_label + * {
        width: 100%;
    }
    .aff_login_blk .w-50 {
        width: 50% !important;
    }
    .register_recaptcha.contact_cppatcha .form-control {
        width: calc(100% - 170px) !important;
    }
}
/**** css changes refer on 24/03/2023 - Ends **********/

/**** css changes refer on 12/07/2023 **********/
@media only screen and (max-width: 767px) {
    .mob_site_content li.header_sub_menu>a {
        width: 100%;
    }
    .cart_pro_su .left_blk {
        width: 100%;
    }
    .cart_pro_su .right_blk {
        width: 100%;
        margin-top: 6px;
    }
    body .main_menu_blk ul li .shop_menu a {
        padding: 0;
    }
    body .shop_menu>*:not(:last-child) {
        padding-bottom: 15px;
    }
    .headere_mobile .header_sub_menu .dropdown-menu {
        padding-top: 15px;
    }
    .mob_site_content li a {
        display: flex;
    }
    .mob_site_content li.header_sub_menu a.shop_all_link {
        text-align: left;
    }
    .mob_site_content li.header_sub_menu li {
        width: 100%;
    }
    body .top_sel_below_sec {
        background-size: cover;
    }
}
/**** css changes refer on 12/07/2023 - Ends **********/

/**** css changes on 19/03/2024 **********/
@media only screen and (max-width: 767px) {
    body .main_menu_blk ul li a.shop_all_link {
        font-size: 14px;
        line-height: 17px;
    }
    body .pro_dec_blk {
           padding: 0px 15px 30px 15px;
       }
       body .pro_dec_blk .pro_det_div_des {
           padding-left: 0;
           padding-right: 0;
       }
       body .product_search_input {
           width: 100%;
           margin-top: 20px;
       }
       .mobile_margin_top {
           margin-top: 15px;
       }
       .cart_pro_su .left_blk {
           width: 100%;
       }
       .cart_pro_su .right_blk {
           width: 100%;
           margin-top: 6px;
       }
       body .login_container {
           min-height: inherit;
       }
       body .pro_filter_sec {
           width: calc(100% - 30px);
       }
       .pro_filter_sec>*:first-child, .pro_filter_sec>*:nth-child(2) {
           margin-bottom: 10px;
       }
       .header_blk .mob_header_logo {
           border: none;
           margin: 0;
           float: none;
           padding: 20px;
       }
       .headere_mobile {
           display: flex;
           align-items: center;
           padding: 15px 15px 15px 0;
           flex-wrap: wrap;
       }
       .headere_mobile .header_right_blk {
            width: calc(100% - 50px);
            display: flex;
            align-items: center;
            justify-content: space-between;
       }
       .headere_mobile .mob_site_content {
           width: 100%;
           background-color: var(--def_color);
           overflow: auto;
           height: 100%;
       }
       .menu_close {
           padding: 20px;
           pointer-events: none;
       }
      .headere_mobile .mob_site_content li.header_sub_menu>a {
        border: none;
    }
       .headere_mobile .mob_site_content li a {
           justify-content: space-between;
       }
       .headere_mobile .header_sub_menu .dropdown-menu {
           position: static !important;
           box-shadow: none;
           display: block;
           border-bottom: 1px solid var(--text_color) !important;
           padding-top: 15px;
       }
       .headere_mobile .shop_menurow>* {
           width: 50% !important;
       }
       .headere_mobile .shop_menurow>* .shop_menu_li {
           border: none;
       }
       .headere_mobile .shop_menurow {
           margin-left: -15px;
           margin-right: -15px;
           width: calc(100% + 30px);
       }
       .headere_mobile .shop_menurow>* {
           padding-left: 15px;
           padding-right: 15px;
           padding-bottom: 30px;
       }
       .headere_mobile .main_menu_blk ul li:not(:last-child) {
           margin-right: 0;
       }
   }
   
   @media only screen and (max-width: 1024px) and (min-width:768px) {
    body .support_tab_blk ul {
        width: 100%;
    }
    .sup_pro_row .col-md-3 {
        flex: inherit;
        max-width: 33.33%;
    }
    body .pro_det_div_des img {
        width: 100%;
        height: auto;
    }
    body .support_tab_blk_ul {
        width: 100%;
        margin-bottom: 10px;
    }
    body .endless_support_tab_sec .public_bundle_product .owl-theme .owl-nav {
        width: 720px;
        top: -98px;
        padding: 0 15px;
    }
    body .support_tab_blk {
        width: 100%;
        flex-wrap: wrap;
        padding: 0;
    }
       .mob_menu_wrapper.d-md-none {
           display: block !important;
       }
       body .pro_dec_blk {
           padding: 0px 20px 30px 20px;
       }
       body .pro_dec_blk .pro_det_div_des {
           padding-left: 0;
           padding-right: 0;
       }
       .main_menu_blk ul li a img {
           position: relative;
           top: -2px;
       }
       body .login_container {
           min-height: calc(100vh - 147px);
       }
       .mob_menu_wrapper.d-md-none .mob_header_logo {
           display: none;
       }
       .mob_menu_wrapper.d-md-none .mob_site_content {
           display: flex;
           justify-content: center;
       }
       .headere_mobile .header_right_blk {
           display: none;
       }
       .menu_close {
           display: none !important;
       }
       .header_blk {
           display: flex;
           flex-direction: column-reverse;
       }
       .mob_menu_wrapper.d-md-none {
           display: block !important;
           padding: 15px 0;
           border-top: 1px solid #fff;
       }
       .mob_menu_wrapper.d-md-none .main_menu_blk {
           display: block;
       }
       .shop_menurow .w-20.col {
           width: 33.33% !important;
       }
       body .header_sub_menu .dropdown-menu {
           top: 157px !important;
       }
       .login_li{
           display: none !important;
       }
       .cart_row.row>* {
           width: 100%;
           flex: inherit;
           max-width: inherit;
           padding-bottom: 34px;
       }
   }
   /**** css changes on 19/03/2024 - Ends **********/

/**** css changes on 03/07/2024 **********/
@media screen and (min-width: 1300px) {
    .w-550 {
        width: 550px !important;
    }
    .learn_menu_blk {
        max-width: 13%;
        flex: inherit;
    }
    .contact_footer_blk {
        flex: inherit;
        max-width: 27%;
    }
}
@media only screen and (max-width: 767px) {
    body .banner_section.public_bundle_product .owl-theme .owl-nav [class*='owl-'] {
        display: none;
    }
    body .public_ban_text {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 30px;
    }
    .banner_below .row>*:not(:last-child) {
        padding-bottom: 20px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    body .public_ban_text {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 30px;
    }
    body .banner_section.public_ban_section .refer_banner_slide .carousel-item .row {
        height: auto;
    }
    .public_banner_slide .row>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
/**** css changes on 03/07/2024 **********/
